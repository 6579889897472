import React, { useEffect } from "react";
import * as Icons from "@wix/wix-ui-icons-common";
import {
  Box,
  Card,
  WixDesignSystemProvider,
  Text,
  Page,
  Loader,
  TableActionCell,
  Table,
  TableToolbar,
  Button,
  FormField,
  Input,
  Badge,
  Tooltip,
  Notification,
} from "@wix/design-system";
import "@wix/design-system/styles.global.css";
import { ReactTagManager } from "react-gtm-ts";
import * as basePlatform from "@certifiedcode/base";

const tagManagerArgs = {
  code: "GTM-WZQPMC7",
};

const definitions: any = {
  ecpay: {
    name: "綠界科技 ECPay",
    description: "在此設置訂閱邏輯，當保存後，即時生效。",
    fields: [
      {
        id: "PeriodAmount",
        name: "每次授權金額",
        description: "每次要授權(扣款)的金額。",
      },
      {
        id: "PeriodType",
        name: "週期種類",
        description:
          "週期種類，可設定以天為扣款週期，或以月份為扣款週期。請輸入 D、M 或 Y。",
        options: [
          {
            label: "天",
            value: "D",
          },
          {
            label: "月",
            value: "M",
          },
          {
            label: "年",
            value: "Y",
          },
        ],
      },
      {
        id: "Frequency",
        name: "執行頻率",
        description:
          "此參數用來定義多久要執行一次。\n\n例如：\n\n當 週期種類 設為 天 時，此參數可設定值為 1~365 (天)。\n\n當 週期種類 設為 月 時，此參數可設定值為 1~12 (月)。\n\n當 週期種類 設為 年 時，此參數只可設定值為 1 (年)。",
        //       當 PeriodType 設為 D 時，可設定值為 1~365 (天)。
        // 當 PeriodType 設為 M 時，可設定值為 1~12 (月)。
        // 當 PeriodType 設為 Y 時，只可設定值為 1 (年)。
      },
      {
        id: "ExecTimes",
        name: "執行次數",
        description:
          "此參數用來定義總共要執行幾次。\n\n例如：\n\n當 週期種類 設為 天 時，最多可設 999 次。\n\n當 週期種類 設為 月 時，最多可設 99 次。\n\n當 週期種類 設為 年 時，最多可設 9 次。",
        //       當 PeriodType 設為 D 時，最多可設 999 次。
        // 當 PeriodType 設為 M 時，最多可設 99 次。
        // 當 PeriodType 設為 Y 時，最多可設 9 次。
      },
    ],
  },
  newebpay: {
    name: "藍新金流 Newebpay",
    description: "在此設置訂閱邏輯，當保存後，即時生效。",
    fields: [
      {
        id: "PeriodType",
        name: "週期類別",
        description: `此委託授權交易週期類別
D=固定天期制
W=每週
M=每月
Y=每年
2.授權週期：固定天期(2-999 天)，以授
權日期隔日起算
3.每月授權若當月沒該日期則由該月最
後一天做為扣款日
4.每次委託每個期別僅能授權一次，若
需授權多次，請建立多張委託`,

        options: [
          {
            label: "固定天期制",
            value: "D",
          },
          {
            label: "每週",
            value: "W",
          },
          {
            label: "每月",
            value: "M",
          },
          {
            label: "每年",
            value: "Y",
          },
        ],
      },
      {
        id: "PeriodPoint",
        name: "交易週期 授權時間",
        description: `1.修改此委託於週期間，執行信用卡授
權交易的時間點
2.當 PeriodType = D，此欄位值限為數
字 2~999，以授權日期隔日起算。
 例：數值為 2，則表示每隔兩天會執
行一次委託
3.當 PeriodType =W，此欄位值限為數
字 1~7，代表每週一至週日。
 例：每週日執行授權，則此欄位值
為 7；若週日與週一皆需執行授權，請
分別建立 2 張委託
3.當 PeriodType = M，此欄位值限為數
字 01~31，代表每月 1 號~31 號。若當
月沒該日期則由該月的最後一天做為
扣款日
 例：每月 1 號執行授權，則此欄位
值為 01；若於 1 個月內需授權多次，
請以建立多次委託方式執行。
5.當 PeriodType =Y，此欄位值格式為
MMDD`,
      },
      {
        id: "PeriodStartType",
        name: "交易模式",
        description: `此委託成立後，是否立即進行信用卡
授權交易，作為檢查信用卡之有效性
１=立即執行十元授權
２=立即執行委託金額授權
３=不檢查信用卡資訊，不授權`,
        options: [
          {
            label: "立即執行十元授權",
            value: "1",
          },
          {
            label: "立即執行委託金額授權",
            value: "2",
          },
          {
            label: "不檢查信用卡資訊，不授權",
            value: "3",
          },
        ],
      },
      {
        id: "PeriodTimes",
        name: "授權期數",
        description: `1.此委託執行信用卡授權交易的次數
2.若授權期數大於信用卡到期日，則系
統自動以信用卡到期日為最終期數。
例：PeriodType = M，於2016/10/1建立
委託，授權期數為12，而付款人之信
用卡到期月/年為12/16時，則此張委託
之授權期數為3，2016/10月、11月、
12月，共3期`,
      },
      {
        id: "PeriodFirstdate",
        name: "首期授權日",
        description: `1.可以指定首期授權日期，此日期當天
會執行第1次授權，隔日為授權週期起
算日。格式為『YYYY/mm/dd』
如:2021/03/31
例:當PeriodType=D,PeriodTimes=40,
PeriodFirstdate=2020/12/22時，則藍新
金流將於2020/12/22當天發動第1期授
權，2020/12/23後每隔40天發動授權
2.本欄位只有PeriodType=D及
PeriodStartType=3時有效
(兩條件須同時滿足)
3. 首期授權日(含當日)執行後，方可執
行修改委託功能
例:首期授權日設定為2022/1/1，於
2022/1/2 凌晨0點前，將無法執行修改
委託動作`
      }
    ],
  },
};

function App() {
  ReactTagManager.init(tagManagerArgs);
  const token = new URLSearchParams(window.location.search).get("token");
  const instance = new URLSearchParams(window.location.search).get("instance");
  const [isUpgraded, setIsUpgraded] = React.useState(false);
  const [instanceData, setInstanceData] = React.useState({
    instance: {
      isFree: true,
      availablePlans: [],
      instanceId: "",
    },
  } as any);
  const [plans, setPlans] = React.useState([] as any);
  const [currentPlanId, setCurrentPlanId] = React.useState("");
  const [paymentMethodId, setPaymentMethodId] = React.useState("ecpay");

  const [showSuccess, setShowSuccess] = React.useState(false);
  const [showError, setShowError] = React.useState(false);

  const baseUrl = "https://certifiedcode.wixsite.com/recurring-ext/_functions";

  const currentPlan = plans.find((plan: any) => plan.id === currentPlanId);

  const currentConfig = currentPlan?.config?.config || {};

  useEffect(() => {
    basePlatform.initialize({
      baseURL: "https://certifiedcode.wixsite.com/recurring-ext/_functions", // Replace with your API base URL
      applicationID: "8adeaca6-2870-4afc-80f7-c68775925398", // Replace with your application ID
    });
    basePlatform.setIdentity(instance);
    basePlatform.setInstance(setInstanceData, setIsUpgraded);
    fetch(`${baseUrl}/plans`, {
      headers: {
        Authorization: instance!,
      },
    })
      .then((res) => res.json())
      .then((data) => {
        setPlans(data.plans);
      })
      .catch(() => {
        setShowError(true);
      });
  }, []);

  useEffect(()=>{
    if (instanceData?.instance?.instanceId === "ba68051a-a38b-4428-91a9-83b6dd2a0fe0") {
      setPaymentMethodId("newebpay");
    }
  }, [instanceData])

  const handleSave = () => {
    fetch(`${baseUrl}/plans/${currentPlanId}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: instance!,
      },
      body: JSON.stringify(currentPlan.config?.config),
    })
      .then((res) => res.json())
      .then((data) => {
        setPlans(
          plans.map((plan: any) =>
            plan.id === currentPlanId ? { ...plan, config: data } : plan
          )
        );
        setCurrentPlanId("");
        setShowSuccess(true);
      })
      .catch(() => {
        setShowError(true);
      });
  };

  const handleDelete = () => {
    fetch(`${baseUrl}/plans/${currentPlanId}`, {
      method: "DELETE",
      headers: {
        Authorization: instance!,
      },
    })
      .then(() => {
        setPlans(plans.filter((plan: any) => plan.id !== currentPlanId));
        setCurrentPlanId("");
        setShowSuccess(true);
      })
      .catch(() => {
        setShowError(true);
      });
  };

  if (token) {
    return (
      <WixDesignSystemProvider features={{ newColorsBranding: true }}>
        <Page height="100vh">
          <Page.Content>
            <Box height={"90vh"} direction="vertical" verticalAlign="middle">
              <Loader text="Loading" />
            </Box>
          </Page.Content>
        </Page>
      </WixDesignSystemProvider>
    );
  }

  const columnWidth = 240;

  const columns = [
    {
      title: "Name",
      render: (row: any) => (
        <Box gap="SP1">
          <Text>{row.name}</Text>
          {row.pricing.subscription ? (
            <Tooltip
              content={`Pricing plans with built-in subscription settings cannot be used along with your payment provider, ${paymentMethodId}.`}
            >
              <Badge
                skin="neutralLight"
                showTooltip
                prefixIcon={<Icons.StatusDeniedFilledSmall />}
              >
                Not Eligible
              </Badge>
            </Tooltip>
          ) : row.config._id ? (
            <Tooltip
              content={`This plan is currently active and can be used with your payment provider, ${paymentMethodId}.`}
            >
              <Badge
                skin="success"
                showTooltip
                prefixIcon={<Icons.StatusCompleteFilledSmall />}
              >
                Active
              </Badge>
            </Tooltip>
          ) : (
            <Tooltip
              content={`This plan is pending setup and can be used with your payment provider, ${paymentMethodId}.`}
            >
              <Badge
                skin="warning"
                showTooltip
                prefixIcon={<Icons.StatusWarningFilledSmall />}
              >
                Pending Setup
              </Badge>
            </Tooltip>
          )}
        </Box>
      ),
      width: columnWidth,
    },
    {
      title: "Visibility",
      render: (row: any) => (row.public ? <Icons.Visible /> : <Icons.Hidden />),
      width: columnWidth,
    },
    {
      title: "",
      render: (row: any) => (
        <TableActionCell
          size="small"
          // @ts-ignore
          primaryAction={{
            text: "Edit",
            onClick: () => {
              setCurrentPlanId(row.id);
            },
            disabled: !!row.pricing.subscription,
          }}
          // secondaryActions={secondaryActions}
          moreActionsTooltipText="More actions"
        />
      ),
      stickyActionCell: true,
      width: 30,
    },
  ];

  return (
    <WixDesignSystemProvider features={{ newColorsBranding: true }}>
      <Notification
        theme="success"
        show={showSuccess}
        autoHideTimeout={3000}
        onClose={() => setShowSuccess(false)}
      >
        <Notification.TextLabel>
          Recurring plan saved successfully!
        </Notification.TextLabel>
        <Notification.CloseButton />
      </Notification>
      <Notification
        theme="error"
        show={showError}
        autoHideTimeout={3000}
        onClose={() => setShowError(false)}
      >
        <Notification.TextLabel>
          An error occurred. Please try again.
        </Notification.TextLabel>
        <Notification.CloseButton />
      </Notification>
      <Page>
        <Page.Header
          showBackButton={!!currentPlanId}
          onBackClicked={() => {
            setCurrentPlanId("");
          }}
          title="Manage Recurring Plans"
          subtitle="Introducing the new recurring payments feature for growth and flexibility. Contact CSM for more information."
          actionsBar={
            <Button
              prefixIcon={<Icons.ChatFilled />}
              onClick={() => {
                // @ts-ignore
                window.Intercom("open");
              }}
            >
              Report an issue
            </Button>
          }
        />
        <Page.Content>
          {!currentPlanId && (
            <Card hideOverflow>
              <Table
                horizontalScroll
                stickyColumns={1}
                data={plans}
                columns={columns}
              >
                <TableToolbar>
                  <TableToolbar.ItemGroup position="start">
                    <TableToolbar.Item>
                      <Text size="small">
                        {plans.length} plans from Pricing Plans
                      </Text>
                    </TableToolbar.Item>
                  </TableToolbar.ItemGroup>
                </TableToolbar>
                <Table.Content />
              </Table>
            </Card>
          )}
          {currentPlanId && (
            <Card>
              <Card.Header
                title={`Recurring payments for ${currentPlan.name}`}
              />
              <Card.Divider />
              <Card.Content>
                <Box gap="SP1" direction="vertical">
                  {definitions[paymentMethodId].fields.map((field: any) => (
                    <FormField
                      key={field.id}
                      label={field.name}
                      infoContent={field.description}
                    >
                      <Input
                        value={currentConfig[field.id] || ""}
                        onChange={(e) => {
                          setPlans(
                            plans.map((plan: any) => {
                              if (plan.id === currentPlanId) {
                                return {
                                  ...plan,
                                  config: {
                                    ...plan.config,
                                    config: {
                                      ...plan.config.config,
                                      [field.id]: e.currentTarget.value,
                                    },
                                  },
                                };
                              }
                              return plan;
                            })
                          );
                        }}
                      />
                    </FormField>
                  ))}

                  <Button onClick={handleSave}>
                    {currentPlan?.config?._id
                      ? "Save Changes"
                      : "Activate Recurring Payments"}
                  </Button>
                  <Button
                    skin="destructive"
                    onClick={handleDelete}
                    disabled={!currentPlan?.config?._id}
                  >
                    Delete Recurring Plan
                  </Button>
                </Box>
              </Card.Content>
            </Card>
          )}
        </Page.Content>
      </Page>
    </WixDesignSystemProvider>
  );
}

export default App;
